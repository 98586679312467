import { useEffect, useState, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Modal from "../../widgets/Modal";
import StyledBounceLoader from "../../StyledBounceLoader";
import TextField from "../../fields/TextField";
import AdminContext from "../../../context/admin";
import { isAdmin } from "../../../lib/helpers";
import { BounceLoader } from "react-spinners";

const BASE_URL = import.meta.env.VITE_API_DOMAIN;

type SystemData = {
  "roth_single_annual_income_min": string;
  "roth_married_annual_income_min": string;
  "roth_married_annual_income_max": string;
  "high_interest_threshold": string;
  "discount_rate": string;
  "cape_index": string;
  "asset_allocations": Object[];
  "glide_paths": Object[];
};

export default function System() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();

  const [loading, setLoading] = useState(true);
  const [posting, setPosting] = useState(false);
  const [errors, setErrors] = useState({});
  const [systemData, setSystemData] = useState<SystemData>({} as SystemData);

  async function fetchSystemData() {
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(BASE_URL + "/api/v2/system", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.status != 200) {
      return;
    }

    const data = await response.json();

    setSystemData(data);
    setLoading(false);
  }

  async function postSystemData() {
    const accessToken = await getAccessTokenSilently();

    const response = await fetch(BASE_URL + "/api/v2/system", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(systemData),
    });

    if (response.status != 201) {
      return;
    }

    setPosting(false);
  }

  function handleChange(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setSystemData({ ...systemData, [name]: value });
  }

  function handleAssetAllocationChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const index = target.getAttribute("index");

    setSystemData((prevSystemData: SystemData) => ({
      ...prevSystemData,
      ['asset_allocations']: {...prevSystemData['asset_allocations'], [index]:{ ...prevSystemData['asset_allocations'][index], [name]: value}},
    }));
  }

  function handleGlidePathChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const index = target.getAttribute("index");

    setSystemData((prevSystemData: SystemData) => ({
      ...prevSystemData,
      ['glide_paths']: {...prevSystemData['glide_paths'], [index]:{ ...prevSystemData['glide_paths'][index], [name]: value}},
    }));
  }

  function handleSubmit(event: any) {
    event.preventDefault();
    setPosting(true);
    postSystemData();
  }

  useEffect(() => {
    if (!isLoading) {
      fetchSystemData();
    }
  }, [isLoading]);

  if (!isLoading && !isAdmin(user)) {
    location.href = "/";
  }

  if (loading) {
    return (
      <div className="mt-24 flex items-center justify-center">
        <StyledBounceLoader />
      </div>
    );
  }

  return (
    <div className="-my-8 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="my-4 inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div className="clear-right overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-8 gap-6 bg-white p-6 pb-12">
              <div className="col-span-8">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Edit System Variables
                </h3>
                <div className="mt-2 border-t border-gray-200"></div>
              </div>

              <div className="col-span-2">
                <TextField
                  name="CAPE Index"
                  slug="cape_index"
                  value={systemData}
                  handleChange={handleChange}
                  errors={errors}
                />
              </div>

              <div className="col-span-2">
                <TextField
                  name="High Interest Threshold"
                  slug="high_interest_threshold"
                  value={systemData}
                  handleChange={handleChange}
                  errors={errors}
                />
              </div>

              <div className="col-span-2">
                <TextField
                  name="Discount Rate"
                  slug="discount_rate"
                  value={systemData}
                  handleChange={handleChange}
                  errors={errors}
                />
              </div>
              
              <div className="col-span-2">
              </div>

              <div className="col-span-2">
                <TextField
                  name="Roth lower income limit (single)"
                  slug="roth_single_annual_income_min"
                  value={systemData}
                  handleChange={handleChange}
                  errors={errors}
                />
              </div>

              <div className="col-span-2">
                <TextField
                  name="Roth lower income limit (MFJ)"
                  slug="roth_married_annual_income_min"
                  value={systemData}
                  handleChange={handleChange}
                  errors={errors}
                />
              </div>

              <div className="col-span-2">
                <TextField
                  name="24% MFJ Upper Tax Bracket"
                  slug="roth_married_annual_income_max"
                  value={systemData}
                  handleChange={handleChange}
                  errors={errors}
                />
              </div>

              <div className="col-span-8 pt-5">
                <h3 className="text-md font-medium leading-6 text-gray-900">
                  Edit Asset Allocation
                </h3>
                <div className="mt-2 border-t border-gray-200"></div>
              </div>

              {systemData &&
                "asset_allocations" in systemData &&
                Object.values(systemData["asset_allocations"]).map(
                  (fund: any, i) => {
                    return (
                      <div
                        key={`child_${i}`}
                        className="col-span-8 grid grid-cols-8 gap-6"
                      >

                        <div className="col-span-1">
                          <TextField
                            name="Fixed Income"
                            slug="fixed_income"
                            index={i}
                            value={fund}
                            handleChange={(e: any) => handleAssetAllocationChange(e) }
                            errors={errors}
                          />
                        </div>
                        <div className="col-span-1">
                          <TextField
                            name="Equity"
                            slug="equity"
                            index={i}
                            value={fund}
                            handleChange={(e: any) => handleAssetAllocationChange(e) }
                            errors={errors}
                          />
                        </div>
                        <div className="col-span-1">
                          <TextField
                            name="US Small Cap"
                            slug="us_small_cap"
                            index={i}
                            value={fund}
                            handleChange={(e: any) => handleAssetAllocationChange(e) }
                            errors={errors}
                          />
                        </div>
                        <div className="col-span-1">
                          <TextField
                            name="US Mid Cap"
                            slug="us_mid_cap"
                            index={i}
                            value={fund}
                            handleChange={(e: any) => handleAssetAllocationChange(e) }
                            errors={errors}
                          />
                        </div>
                        <div className="col-span-1">
                          <TextField
                            name="US Large Cap"
                            slug="us_large_cap"
                            index={i}
                            value={fund}
                            handleChange={(e: any) => handleAssetAllocationChange(e) }
                            errors={errors}
                          />
                        </div>
                        <div className="col-span-1">
                          <TextField
                            name="International Equity"
                            slug="international_equity"
                            index={i}
                            value={fund}
                            handleChange={(e: any) => handleAssetAllocationChange(e) }
                            errors={errors}
                          />
                        </div>
                        <div className="col-span-1">
                          <TextField
                            name="Emerging Market"
                            slug="emerging_market_equity"
                            index={i}
                            value={fund}
                            handleChange={(e: any) => handleAssetAllocationChange(e) }
                            errors={errors}
                          />
                        </div>

                      </div>
                    );
                  },
                )}

              <div className="col-span-8 pt-5">
                <h3 className="text-md font-medium leading-6 text-gray-900">
                  Edit Glide Path
                </h3>
                <div className="mt-2 border-t border-gray-200"></div>
              </div>

              {systemData &&
                "glide_paths" in systemData &&
                Object.values(systemData["glide_paths"]).map(
                  (fund: any, i) => {
                    return (
                      <div
                        key={`child_${i}`}
                        className="col-span-8 grid grid-cols-8 gap-6"
                      >

                        <div className="col-span-2">
                          <TextField
                            name="Years to Retirement"
                            slug="years_to_retirement"
                            index={i}
                            value={fund}
                            handleChange={(e: any) => handleGlidePathChange(e) }
                            errors={errors}
                          />
                        </div>
                        <div className="col-span-2">
                          <TextField
                            name="Equity Allocation"
                            slug="equity_allocation"
                            index={i}
                            value={fund}
                            handleChange={(e: any) => handleGlidePathChange(e) }
                            errors={errors}
                          />
                        </div>
                        <div className="col-span-2">
                          <TextField
                            name="Fixed Income Allocation"
                            slug="fixed_income_allocation"
                            index={i}
                            value={fund}
                            handleChange={(e: any) => handleGlidePathChange(e) }
                            errors={errors}
                          />
                        </div>

                      </div>
                    );
                  },
                )}

            </div>

            

            <div className="bg-gray-100 px-4 py-3 text-right sm:px-6">
              <button
                type="submit"
                disabled={loading}
                className={`${
                  loading ? "bg-gray-400" : "bg-primary"
                } inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm`}
              >
                <BounceLoader loading={posting} size={20} color="#ffffff" />
                <span className={posting ? "ml-2" : ""}>Save</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
