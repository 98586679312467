import React, { useState, useContext, useRef, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { useMachine } from "@xstate/react";
import { createMachine } from "xstate";
import { useAuth0 } from "@auth0/auth0-react";

import ChatLog from "./modules/ChatLog";
import { validate } from "./modules/validate";
import { getSectionName } from "./modules/business";
import { checkConditional } from "./modules/conditional";
import Modal from "./widgets/Modal";

import "./ChatInterview.css";
import TenantContext, { TenantMachineDataType } from "../context/tenant";
import { fetchQueryOperation } from "../lib/data";
import useFormData from "../lib/use-form-data";
import useChatData from "../lib/use-chat-data";
import StyledBounceLoader from "./StyledBounceLoader";
import AdminContext from "../context/admin";

type MetaType = {
  conditionals: any;
  video: any;
  fields: any;
  clearFields: any;
  buttonConditionals: any;
  prevClear: any;
  hideNext: boolean;
  hidePrev: boolean;
  apiCall: any;
  showReturn: boolean;
};

type CurrentlyEditingType = {
  name: string;
  validation: any;
  currentlyEditing: any;
  clearSections: any;
};

type ChatInterviewProps = {
  chatData: any;
  section: string;
  errors: any;
  setErrors: any;
  showModal: any;
  restartSections: (sections: string[]) => Promise<void>;
};

export default function Chat({
  chatData,
  section,
  errors,
  setErrors,
  showModal,
  restartSections,
}: ChatInterviewProps) {
  const { getAccessTokenSilently } = useAuth0();
  const { id, machineData } = useContext(TenantContext);
  const { emulatingUser } = useContext(AdminContext)

  const [loading, setLoading] = useState(false);

  const m = useMemo<TenantMachineDataType>(() => machineData ?? {}, undefined);

  const stateMachine = createMachine({
    ...m[section],
    predictableActionArguments: true,
  });

  stateMachine.config.initial = chatData[section]?.state?.value ?? "welcome";

  let [state, send] = useMachine(() => stateMachine);
  const meta: MetaType = Object.values(state.meta)[0] as any;
  const [currentVideo, setCurrentVideo] = useState<string | null>(null);
  const [currentlyEditing, setCurrentlyEditing] =
    useState<CurrentlyEditingType | null>(null);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [sectionsToClear, setSectionsToClear] = useState([]);

  const currentMessageRef = useRef(null);

  const { formData, mutateFormData, trigger } = useFormData();
  const { triggerUpdateChatData } = useChatData();

  useEffect(() => {
    if (chatData) {
      stateMachine.config.initial =
        chatData[section]?.state?.value ?? "welcome";
    }
  }, [chatData]);

  async function next() {
    if (!isValid()) {
      return;
    }

    await forwardStateMachine();
  }

  function prev() {
    reverseStateMachine();
  }

  async function forwardStateMachine() {
    let sendTo = "NEXT";

    if (meta.conditionals) {
      meta.conditionals.forEach((conditional: any) => {
        if (conditional.step && checkConditional(conditional, formData)) {
          sendTo = conditional.step;
        }
      });
    }

    if (meta.fields) {
      meta.fields.forEach((field: any) => {
        if (field.options) {
          // @ts-ignore
          let value = formData[field.name];
          field.options.forEach((option: any) => {
            if (value === option.name && option.step) {
              sendTo = option.step;
            }
          });
        }
      });
    }

    if (meta.clearFields) {
      meta.clearFields.forEach(
        (field: { conditional: string; name: string }) => {
          if (
            !field.conditional ||
            checkConditional(field.conditional, formData)
          ) {
            // @ts-ignore
            delete formData[field.name];
            mutateFormData({ ...formData }, { revalidate: false });
          }
        },
      );
    }

    const { data } = await apiCall();

    await trigger(
      { ...formData, ...data?.results },
      { optimisticData: { ...formData, ...(data?.results || []) } },
    );

    send(sendTo);
    addNextMetaToChatLog(sendTo);
  }

  function reverseStateMachine() {
    send("PREV");
    removePrevMetaFromChatLog();
  }

  function step(event: any) {
    const target = event.target || event.srcElement;
    const buttonName = target.getAttribute("name");
    const buttonValue = target.innerHTML;

    let sendTo = target.getAttribute("nextstep");

    if (meta.buttonConditionals) {
      meta.buttonConditionals.forEach((conditional: any) => {
        if (
          conditional.step &&
          conditional.buttonValue == buttonValue &&
          checkConditional(conditional, formData)
        ) {
          sendTo = conditional.step;
        }
      });
    }

    if (meta.clearFields) {
      meta.clearFields.forEach(
        (field: { conditional: string; name: string }) => {
          if (
            !field.conditional ||
            checkConditional(field.conditional, formData)
          ) {
            // @ts-ignore
            delete formData[field.name];
            mutateFormData({ ...formData }, { revalidate: false });
          }
        },
      );
    }

    send(sendTo);
    addNextMetaToChatLog(sendTo);
    trigger(
      { ...formData, [buttonName]: buttonValue },
      { optimisticData: { ...formData, [buttonName]: buttonValue } },
    );
  }

  async function onEnter(slug: any) {
    if (!currentlyEditing) {
      return;
    }
    if (slug != currentlyEditing.name) {
      // assumes all question field states have a NEXT step
      await next();
    } else {
      if (
        !validate(
          { [currentlyEditing.name]: currentlyEditing.validation },
          formData,
          setErrors,
        )
      ) {
        return false;
      }

      if (currentlyEditing.clearSections) {
        confirmClearSections(currentlyEditing.clearSections);
      }

      // don't progress state machine if field is previous one being edited
      trigger(formData, { optimisticData: formData });
    }
  }

  function addNextMetaToChatLog(sendTo: any) {
    const currentChatLog = getCurrentChatLog();
    const nextState = stateMachine.transition(state, sendTo);
    const nextStateMeta: MetaType = Object.values(nextState.meta)[0] as any;

    // store name of state in the chatlog
    // @ts-ignore
    nextStateMeta["value"] = nextState.value;

    if (nextStateMeta.video) {
      setCurrentVideo(nextStateMeta.video);
    }
    updateChatData([...currentChatLog, nextStateMeta], nextState);
  }

  function removePrevMetaFromChatLog() {
    let currentChatLog = getCurrentChatLog();
    const prevState = stateMachine.transition(state, "PREV");

    currentChatLog.pop();

    // remove extra steps from chatLog when PREV jumps over multiple states
    const prevClearList = meta.prevClear || [];
    for (let i = 0; i < prevClearList.length; i++) {
      let topOfChatLog = currentChatLog[currentChatLog.length - 1] || {};
      if (prevClearList.includes(topOfChatLog.value)) {
        currentChatLog.pop();
      }
    }

    updateChatData(currentChatLog, prevState);
  }

  function updateChatData(currentChatLog: any, nextState: any) {
    if (!chatData[section]) {
      chatData[section] = {};
    }

    chatData[section].log = currentChatLog;
    chatData[section].state = nextState;
    // mutateChatData(chatLogs, { revalidate: false });
    triggerUpdateChatData({ ...chatData }, { revalidate: false });
  }

  function isValid() {
    if (meta.fields) {
      let fieldValidations: any = {};
      meta.fields.forEach((field: any) => {
        fieldValidations[field.name] = field.validation;
      });

      //stop progression if validation fails
      if (!validate(fieldValidations, formData, setErrors)) {
        return false;
      }
    }

    return true;
  }

  async function apiCall(): Promise<any> {
    const apiCall: { operation: string } = meta.apiCall;

    if (!apiCall) {
      return {};
    }

    const accessToken = await getAccessTokenSilently();
    try {
      setLoading(true);
      return await fetchQueryOperation(accessToken, apiCall.operation, id, emulatingUser);
    } catch (e: any) {
      if (e.response && e.response.status === 422) {
        setErrorMessage(
          e.response.data?.message ||
            "Required data is missing, you need complete previous sections before you can proceed in this section.",
        );
      } else {
        setErrorMessage(`Failed loading data: ${e}`);
      }

      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  }

  function getCurrentChatLog() {
    const currentChatLogArray = chatData[section];

    if (currentChatLogArray) {
      return currentChatLogArray.log;
    } else {
      return [meta]; //start with initial state's data if none is stored
    }
  }

  function editField(field: any) {
    // if field has clearSections property then restart sections instead of editing field
    if (field.clearSections) {
      confirmClearSections(field.clearSections);
    } else {
      setCurrentlyEditing(field);
    }
  }

  function confirmClearSections(clearSections: any) {
    let sectionsToClear = clearSections.sections;
    let includeConditionalSections = false;

    if (clearSections.conditionals) {
      clearSections.conditionals.forEach((conditional: any) => {
        if (checkConditional(conditional, formData)) {
          includeConditionalSections = true;
        }
      });
    }

    if (includeConditionalSections) {
      sectionsToClear = sectionsToClear.concat(
        clearSections.conditionalSections,
      );
    }

    setSectionsToClear(sectionsToClear);
    setShowConfirmModal(true);
  }

  function handleClearSectionsConfirm() {
    restartSections(sectionsToClear);
  }

  function hideErrorModal() {
    setShowErrorModal(false);
  }

  if (!chatData) {
    return (
      <div className="block sm:order-2 sm:w-2/3">
        <div className="mt-24 flex items-center justify-center">
          <StyledBounceLoader />
        </div>
      </div>
    );
  }

  return (
    <div className="block sm:order-2 sm:w-2/3">
      <div className="mx-4 overflow-hidden bg-white shadow sm:mx-0 sm:rounded-md">
        {/* TODO: move to ChatModals */}
        <Modal show={showErrorModal} handleClose={hideErrorModal}>
          <div className="bg-white p-6 pb-12">
            <p className="px-12 pt-4 font-medium text-gray-700">
              {errorMessage}
            </p>
          </div>
        </Modal>
        <Modal
          show={showConfirmModal}
          handleClose={() => {
            setShowConfirmModal(false);
          }}
          showConfirm={true}
          handleConfirm={handleClearSectionsConfirm}
        >
          <div className="bg-white p-6 pb-12">
            <p className="px-12 pt-4 font-medium text-gray-700">
              Changing your employer requires restarting the <i>Introduction</i>{" "}
              and <i>Investment</i> sections. Please confirm.
            </p>
          </div>
        </Modal>

        {currentVideo && (
          <iframe
            width="854"
            height="480"
            src={`${currentVideo}?autoplay=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}

        <div className="chatlog py-4">
          <div className="relative mb-4">
            <div className="hidden xl:inline-block">
              <div className="mb-10 flex flex-shrink-0 p-2 pl-4 text-lg font-semibold uppercase tracking-wide text-gray sm:mb-0">
                <div className="relative rounded-full bg-gray-400 p-4">
                  <img
                    className="absolute inset-x-2.5 inset-y-1 h-6"
                    src="/images/dorval-chrone-logo-vector-white.svg"
                    alt="Dorval Chrone Logo"
                  ></img>
                </div>
                <span className="ml-2">
                  Dorval & Chorne{" "}
                  <span className="hidden sm:inline">Financial Advisors</span>
                </span>
              </div>
            </div>
            <div className="inline-block">
              <div className="absolute right-0 top-0 mr-4 lg:mt-2">
                <span className="text-lg font-semibold uppercase leading-8 text-gray-700">
                  {getSectionName(section)}
                </span>
                <span
                  className={
                    "ml-2 inline inline-flex h-10 w-12 pb-2 align-middle text-primary"
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className=""
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10 3.5a1.5 1.5 0 013 0V4a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-.5a1.5 1.5 0 000 3h.5a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-.5a1.5 1.5 0 00-3 0v.5a1 1 0 01-1 1H6a1 1 0 01-1-1v-3a1 1 0 00-1-1h-.5a1.5 1.5 0 010-3H4a1 1 0 001-1V6a1 1 0 011-1h3a1 1 0 001-1v-.5z" />
                  </svg>
                </span>
              </div>
            </div>
          </div>

          <div style={{ height: window.innerHeight > 800 ? "576px" : "400px" }}>
            {formData && (
              <ChatLog
                currentChatLog={getCurrentChatLog()}
                formValues={formData}
                setFormValues={mutateFormData}
                step={step}
                onEnter={onEnter}
                currentlyEditing={currentlyEditing}
                editField={editField}
                errors={errors}
                meta={meta}
                showModal={showModal}
                currentMessageRef={currentMessageRef}
              />
            )}
          </div>
        </div>

        <div className="h-16 bg-gray-100 px-4 py-3 text-right sm:px-6">
          {meta && !meta.hidePrev && (
            <button
              onClick={prev}
              className={
                "float-left inline-flex justify-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm"
              }
            >
              Back
            </button>
          )}
          {meta && !meta.hideNext && (
            <button
              onClick={next}
              disabled={loading}
              className={`${
                loading ? "bg-gray-400" : "bg-primary"
              } inline-flex justify-center rounded-md px-4 py-2 text-sm font-medium text-white shadow-sm`}
            >
              <span className={loading ? "ml-2" : ""}>Continue</span>
            </button>
          )}
          {meta && meta.showReturn && (
            <Link
              to="/puzzle"
              className={
                "inline-flex justify-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm"
              }
            >
              Return
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
