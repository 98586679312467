import { useContext, useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import TenantContext from "../context/tenant";
import useQueryData from "../lib/use-query-data";

import {
  getAge,
  getEmergencyReserves,
  getInvestmentAssets,
  getListFieldTotal,
  getRetirementAccountType,
  getRetirementContribution,
  getTopInvestmentAccount,
  listHasRecords,
  printBirthday,
  roundDollars,
} from "./modules/business";
import "./VennDiagram.css";
import useFormData from "../lib/use-form-data";
import {
  FormDataAdditionalPropertyType,
  FormDataInvestmentAccountsType,
} from "../lib/types";
import DiagramStudentLoanSection from "./DiagramStudentLoanSection";

export default function VennDiagram() {
  const { id, employeeName } = useContext(TenantContext);
  const { formData: formValues } = useFormData();

  const [hideName, setHideName] = useState(false);
  const [hideFutureIncome, setHideFutureIncome] = useState(false);

  const circleOneRef = useRef<any | null>(null);
  const circleTwoRef = useRef<any | null>(null);

  const nameOneRef = useRef<any | null>(null);
  const nameTwoRef = useRef<any | null>(null);

  const futureOneRef = useRef<any | null>(null);
  const futureTwoRef = useRef<any | null>(null);

  const {
    queryData: { results: progressVesting },
  } = useQueryData(
    "dc_progress_vesting", id === "1"
  );

  useEffect(() => {
    const circleOneBottom =
      circleOneRef.current.offsetTop + circleOneRef.current.offsetHeight;
    const nameOneRefBottom =
      nameOneRef.current.offsetTop + nameOneRef.current.offsetHeight;
    const futureOneRefBottom =
      futureOneRef.current.offsetTop + futureOneRef.current.offsetHeight;

    if (nameOneRefBottom > circleOneBottom) {
      setHideName(true);
    }
    if (futureOneRefBottom > circleOneBottom) {
      setHideFutureIncome(true);
    }

    const circleTwoBottom =
      circleTwoRef.current.offsetTop + circleTwoRef.current.offsetHeight;
    const nameTwoRefBottom =
      nameTwoRef.current.offsetTop + nameTwoRef.current.offsetHeight;
    const futureTwoRefBottom =
      futureTwoRef.current.offsetTop + futureTwoRef.current.offsetHeight;

    if (nameTwoRefBottom > circleTwoBottom) {
      setHideName(true);
    }
    if (futureTwoRefBottom > circleTwoBottom) {
      setHideFutureIncome(true);
    }
  }, []);

  const topInvestmentAccount = getTopInvestmentAccount(
    formValues["investmentAccounts"],
    "You",
  ) as unknown as FormDataInvestmentAccountsType;
  const topSpouseInvestmentAccount = getTopInvestmentAccount(
    formValues["investmentAccounts"],
    "Spouse",
  ) as unknown as FormDataInvestmentAccountsType;
  const jointInvestmentAssets = getInvestmentAssets(
    formValues["investmentAccounts"],
    "Joint",
  ) as unknown as FormDataInvestmentAccountsType;
  const retirementContribution = getRetirementContribution(
    formValues["retirementContributionPreTax"],
    formValues["retirementContributionPostTax"],
    formValues["retirementContribution"],
  );
  const spouseRetirementContribution = getRetirementContribution(
    formValues["spouseRetirementContributionPreTax"],
    formValues["spouseRetirementContributionPostTax"],
    formValues["spouseRetirementContribution"],
  );

  return (
    <div className={`venn tennant-${id}`}>
      <div className="Venn2 my-20 text-gray-500">
        <div ref={circleOneRef} className="circle one uppercase">
          <span></span>
          <span></span>

          <h3 className="first text-md font-bold">Current Income</h3>
          <p style={{ minHeight: "34px" }}>
            <data>{formValues["currentEmployment"]}</data>
            <data>
              {employeeName}&nbsp;- {roundDollars(formValues["income"])}
            </data>
            {id === "1" &&
              getAge(formValues["birthdate"]) > 64 &&
              (formValues["hasGreensheet"] == "Yes" ||
                formValues["hasGreensheetEstimate"] == "Yes" ||
                formValues["hasPeraStatement"] == "Yes" ||
                formValues["hasPeraEstimate"] == "Yes") && (
                <data>
                  MNA PENSION BENEFIT -{" "}
                  {roundDollars(!!progressVesting && !!progressVesting["lifeOnlyBenefit65"] ? progressVesting["currentBenefit"] : 0)}/Mo
                </data>
              )}
          </p>

          <h3 className="text-md font-bold">Retirement Plans</h3>
          <p className="retirement">
            <data>{formValues["currentEmployment"]}</data>
            <data>
              {getRetirementAccountType(formValues["currentEmployment"])}-{" "}
              {roundDollars(formValues["retirementBalance"])}
            </data>
            <data className="text-xs italic">
              Contribution: {retirementContribution}%
            </data>
          </p>

          {!!topInvestmentAccount ? (
            <p className="mt-4 text-sm">
              <data>{topInvestmentAccount.investmentAccountName}</data>
              <data>
                {topInvestmentAccount.investmentAccountType}-{" "}
                {roundDollars(topInvestmentAccount.investmentBalance)}
              </data>
              <data className="text-xs italic">
                Contribution:{" "}
                {roundDollars(topInvestmentAccount.investmentContribution)}
              </data>
            </p>
          ) : (
            <div className="placeholder"></div>
          )}

          <div
            ref={nameOneRef}
            className={`namediv ml-20 text-primary ${
              hideName ? "hide-div" : ""
            }`}
          >
            <h3 className="text-md font-bold">{formValues["nameFirst"]}</h3>
            <p>
              <data className="font-bold">{formValues["nameLast"]}</data>
              <data>{printBirthday(formValues["birthdate"])}</data>
            </p>
          </div>

          <div
            ref={futureOneRef}
            className={`${hideFutureIncome ? "hide-div" : ""}`}
          >
            {id !== "1" && <div className="placeholder"></div>}

            <h3 className="text-md font-bold">Future Income</h3>

            {!!formValues["socialSecurityAmountRetirementAge"] ? (
              <p>
                <data>
                  Social Security (FRA: {formValues["socialSecurityAge"]})
                </data>
                <data>
                  {formValues["socialSecurityAmountPartial"] &&
                    ` @62 - ${roundDollars(
                      formValues["socialSecurityAmountPartial"],
                    )}, `}
                  @{formValues["socialSecurityAge"]} -{" "}
                  {roundDollars(
                    formValues["socialSecurityAmountRetirementAge"],
                  )}
                  {formValues["socialSecurityAmountFull"] &&
                    `, @70 - ${roundDollars(
                      formValues["socialSecurityAmountFull"],
                    )}`}
                </data>
              </p>
            ) : (
              <p>
                <data>Social Security TBD</data>
              </p>
            )}

            {id === "1" &&
              formValues["currentEmployment"] != "HCMC" &&
              formValues["currentEmployment"] != "Essentia Health" &&
              (formValues["hasGreensheet"] == "Yes" ||
                formValues["hasGreensheetEstimate"] == "Yes") &&
              getAge(formValues["birthdate"]) < 65 && (
                <p className="pension" data-tip data-for="currentBenefitTip">
                  <data>
                    MNA Pension
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-grey inline h-5 w-5 pb-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </data>
                  {formValues["employmentPlanToStayMna"] != "No" && (progressVesting && progressVesting["vestingAge"] < 65) && (
                    <data>
                      Est. Life Only @ Rule of 85 ({progressVesting ? progressVesting["vestingAge"] : formValues["vestingAge"]}) -{" "}
                      {progressVesting && !!progressVesting["lifeOnlyBenefit"]
                        ? roundDollars(progressVesting["lifeOnlyBenefit"])
                        : "$0"}
                    </data>
                  )}
                  {formValues["employmentPlanToStayMna"] != "No" && (
                    <data>
                      Est. Life Only @65{" "}
                      {roundDollars(!!progressVesting && !!progressVesting["lifeOnlyBenefit65"] ? progressVesting["lifeOnlyBenefit65"] : 0)}
                    </data>
                  )}
                  {formValues["employmentPlanToStayMna"] == "No" && (
                    // && (yearsOfServices > 5)
                    <data>
                      Est. Life Only Currently{" "}
                      {roundDollars(!!progressVesting && !!progressVesting["lifeOnlyBenefit65"] ? progressVesting["currentBenefit"] : 0)}
                    </data>
                  )}
                </p>
              )}

            {id === "1" &&
              formValues["currentEmployment"] != "HCMC" &&
              formValues["currentEmployment"] != "Essentia Health" &&
              formValues["hasGreensheet"] != "Yes" &&
              formValues["hasGreensheetEstimate"] != "Yes" &&
              getAge(formValues["birthdate"]) < 65 && (
                <p className="pension">
                  <data>MNA Pension TBD</data>
                </p>
              )}

            {id === "1" &&
              formValues["currentEmployment"] == "HCMC" &&
              getAge(formValues["birthdate"]) < 65 &&
              (formValues["hasPeraStatement"] == "Yes" ||
                formValues["hasPeraEstimate"] == "Yes") && (
                <p className="pension">
                  <data>PERA Pension</data>
                  <data>
                    Est. Life Only @66 -{" "}
                    {roundDollars(formValues["peraEstimatedBenefitAt66"])}
                  </data>
                </p>
              )}

            {id === "1" &&
              formValues["currentEmployment"] == "HCMC" &&
              getAge(formValues["birthdate"]) < 65 &&
              formValues["hasPeraStatement"] != "Yes" &&
              formValues["hasPeraEstimate"] != "Yes" && (
                <p className="pension">
                  <data>PERA Pension TBD</data>
                </p>
              )}
          </div>
        </div>

        <div ref={circleTwoRef} className="circle two uppercase">
          <span></span>
          <span></span>

          <h3 className="first text-md font-bold">Current Income</h3>
          <p style={{ minHeight: "34px" }}>
            <data>
              {formValues["spouseHasCurrentEmployment"] == "Yes"
                ? formValues["spouseEmployment"]
                : formValues["spouseNonMnaEmployer"]}
            </data>
            <data>
              {formValues["spouseHasCurrentEmployment"] == "Yes"
                ? employeeName
                : formValues["spouseNonMnaPosition"]}{" "}
              - {roundDollars(formValues["spouseIncome"])}
            </data>
            {id === "1" &&
              getAge(formValues["spouseBirthdate"]) > 64 &&
              formValues["spouseHasCurrentEmployment"] == "Yes" &&
              (formValues["spouseHasGreensheet"] == "Yes" ||
                formValues["spouseGreensheetEstimate"] == "Yes" ||
                formValues["spouseHasPeraStatement"] == "Yes" ||
                formValues["spouseHasPeraEstimate"] == "Yes") && (
                <data>
                  MNA PENSION BENEFIT -{" "}
                  {roundDollars(formValues["spouseCurrentBenefit"])}/Mo
                </data>
              )}
          </p>

          <h3 className="text-md font-bold">Retirement Plans</h3>
          <p className="retirement">
            <data>{formValues["spouseEmployment"]}</data>
            <data>
              {formValues["spouseHasCurrentEmployment"] == "Yes"
                ? getRetirementAccountType(formValues["spouseEmployment"])
                : "401k/403b"}{" "}
              - {roundDollars(formValues["spouseRetirementBalance"])}
            </data>
            <data className="text-xs italic">
              Contribution: {spouseRetirementContribution}%
            </data>
          </p>

          {!!topSpouseInvestmentAccount ? (
            <p className="text-md mt-4">
              <data>{topSpouseInvestmentAccount.investmentAccountName}</data>
              <data>
                {topSpouseInvestmentAccount.investmentAccountType}-{" "}
                {roundDollars(topSpouseInvestmentAccount.investmentBalance)}
              </data>
              <data className="text-xs italic">
                Contribution:{" "}
                {roundDollars(
                  topSpouseInvestmentAccount.investmentContribution,
                )}
              </data>
            </p>
          ) : (
            <div className="placeholder"></div>
          )}

          <div
            ref={nameTwoRef}
            className={`namediv mr-20 text-primary ${
              hideName ? "hide-div" : ""
            }`}
          >
            <h3 className="text-md font-bold">
              {formValues["spouseNameFirst"]}
            </h3>
            <p>
              <data className="font-bold">{formValues["spouseNameLast"]}</data>
              <data>{printBirthday(formValues["spouseBirthdate"])}</data>
            </p>
          </div>
          <div
            ref={futureTwoRef}
            className={`${hideFutureIncome ? "hide-div" : ""}`}
          >
            {id !== "1" && <div className="placeholder"></div>}
            <h3 className="text-md font-bold">Future Income</h3>
            {!!formValues["spouseSocialSecurityAmountRetirementAge"] ? (
              <p>
                <data>
                  Social Security (FRA: {formValues["spouseSocialSecurityAge"]})
                </data>
                <data>
                  {formValues["spouseSocialSecurityAmountPartial"] &&
                    ` @62 - ${roundDollars(
                      formValues["spouseSocialSecurityAmountPartial"],
                    )}, `}
                  @{formValues["spouseSocialSecurityAge"]} -{" "}
                  {roundDollars(
                    formValues["spouseSocialSecurityAmountRetirementAge"],
                  )}
                  {formValues["spouseSocialSecurityAmountFull"] &&
                    `, @70 - ${roundDollars(
                      formValues["spouseSocialSecurityAmountFull"],
                    )}`}
                </data>
              </p>
            ) : (
              <p>
                <data>Social Security TBD</data>
              </p>
            )}

            {id === "1" &&
              formValues["spouseHasCurrentEmployment"] == "Yes" &&
              formValues["spouseEmployment"] != "HCMC" &&
              formValues["spouseEmployment"] != "Essentia Health" &&
              (formValues["spouseHasGreensheet"] == "Yes" ||
                formValues["spouseGreensheetEstimate"] == "Yes") &&
              getAge(formValues["spouseBirthdate"]) < 65 && (
                <p className="pension">
                  <data data-tip data-for="currentBenefitTip">
                    MNA Pension
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-grey inline h-5 w-5 pb-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                  </data>
                  {formValues["spouseEmploymentPlanToStay"] != "No" && (
                    <data>
                      Est. Life Only @ Rule of 85 (
                      {formValues["spouseVestingAge"]}) -{" "}
                      {roundDollars(formValues["spouseLifeOnlyBenefit"])}
                    </data>
                  )}
                  {formValues["spouseEmploymentPlanToStay"] != "No" && (
                    <data>
                      Est. Life Only @65{" "}
                      {roundDollars(formValues["spouseLifeOnlyBenefit65"])}
                    </data>
                  )}
                  {formValues["spouseEmploymentPlanToStay"] == "No" && (
                    <data>
                      Est. Life Only Currently{" "}
                      {roundDollars(formValues["spouseCurrentBenefit"])}
                    </data>
                  )}
                </p>
              )}

            {id === "1" &&
              formValues["spouseHasCurrentEmployment"] == "Yes" &&
              formValues["spouseEmployment"] != "HCMC" &&
              formValues["spouseEmployment"] != "Essentia Health" &&
              formValues["spouseHasGreensheet"] != "Yes" &&
              formValues["spouseGreensheetEstimate"] != "Yes" &&
              getAge(formValues["spouseBirthdate"]) < 65 && (
                <p className="pension">
                  <data>MNA Pension TBD</data>
                </p>
              )}

            {id === "1" &&
              formValues["spouseHasCurrentEmployment"] == "Yes" &&
              formValues["spouseEmployment"] == "HCMC" &&
              getAge(formValues["spouseBirthdate"]) < 65 &&
              (formValues["spouseHasPeraStatement"] == "Yes" ||
                formValues["spouseHasPeraEstimate"] == "Yes") && (
                <p className="pension">
                  <data>PERA Pension</data>
                  <data>
                    Est. Life Only @66 -{" "}
                    {roundDollars(formValues["spousePeraEstimatedBenefitAt66"])}
                  </data>
                </p>
              )}

            {id === "1" &&
              formValues["spouseHasCurrentEmployment"] == "Yes" &&
              formValues["spouseEmployment"] == "HCMC" &&
              getAge(formValues["spouseBirthdate"]) < 65 &&
              formValues["spouseHasPeraStatement"] != "Yes" &&
              formValues["spouseHasPeraEstimate"] != "Yes" && (
                <p className="pension">
                  <data>PERA Pension TBD</data>
                </p>
              )}
          </div>
        </div>

        <div className="shape onetwo uppercase">
          <span></span>
          <span></span>

          <p>
            <data className="first heading text-lg font-bold">Assets</data>
            <data className="mt-1">
              Emergency Reserves -{" "}
              {getEmergencyReserves(
                formValues["checkingAccountBalance"],
                formValues["savingAccountBalance"],
                formValues["otherAccountBalance"],
              )}
            </data>
            {!!formValues["homeEstimatedValue"] && (
              <data className="mt-1">
                Home - {roundDollars(formValues["homeEstimatedValue"])}
              </data>
            )}
            {!!jointInvestmentAssets && (
              <data className="mt-1">
                Investments - {roundDollars(jointInvestmentAssets)}
              </data>
            )}
          </p>

          {listHasRecords(formValues["additionalProperties"], [
            "additionalPropertyType",
          ]) &&
            formValues.additionalProperties
              ?.slice(0, 3)
              .map(
                (property: FormDataAdditionalPropertyType, index: number) => {
                  if (!property.additionalPropertyType) {
                    return;
                  }

                  return (
                    <p
                      className="mt-1 text-sm"
                      key={`${index}_additionalProperties`}
                    >
                      <data>
                        {property.additionalPropertyType + " Property"}-{" "}
                        {roundDollars(property.additionalPropertyValue)}
                      </data>
                    </p>
                  );
                },
              )}

          <p className="mt-4 text-sm">
            <data className="heading text-lg font-bold">Liabilities</data>
            {!!formValues["mortgageAmount"] && (
              <>
                <data className="mt-1">
                  Mortgage - {roundDollars(formValues["mortgageAmount"])}
                </data>
                <data>
                  {roundDollars(formValues["mortgagePayment"])}/Mo ,{" "}
                  {formValues["mortgageInterestRate"] || 0}%
                </data>
              </>
            )}
          </p>

          {listHasRecords(formValues["debtsCreditCard"], [
            "creditCardName",
          ]) && (
            <p className="mt-1 text-sm">
              <data>
                Credit Card Debt -{" "}
                {getListFieldTotal(
                  formValues["debtsCreditCard"],
                  "creditCardBalance",
                )}
              </data>
            </p>
          )}

          {listHasRecords(formValues["debtsAutoLoans"], ["autoLoanName"]) && (
            <p className="mt-1 text-sm">
              <data>
                Car Loan -{" "}
                {getListFieldTotal(
                  formValues["debtsAutoLoans"],
                  "autoLoanBalance",
                )}
              </data>
              <data>
                {getListFieldTotal(
                  formValues["debtsAutoLoans"],
                  "autoLoanPayment",
                )}{" "}
                PMT
              </data>
            </p>
          )}

          <DiagramStudentLoanSection formValues={formValues} />

          {listHasRecords(formValues["additionalProperties"], [
            "additionalPropertyType",
          ]) &&
            formValues.additionalProperties
              ?.slice(0, 3)
              .map(
                (property: FormDataAdditionalPropertyType, index: number) => {
                  if (!property.additionalPropertyType) {
                    return;
                  }

                  return (
                    <p
                      className="mt-1 text-sm"
                      key={`${index}_additionalPropertiesMortgage`}
                    >
                      <data>
                        {property.additionalPropertyType + " Property Mortgage"}
                        -{" "}
                        {roundDollars(
                          property.additionalPropertyMortgageBalance,
                        )}
                      </data>
                    </p>
                  );
                },
              )}
        </div>
      </div>

      <ReactTooltip
        id="currentBenefitTip"
        place="top"
        effect="solid"
        multiline={true}
      >
        {/* The Amount you would receive if you leave today. <br /> */}
        Any estimated MNA pension benefits in this application are for
        informational <br />
        purposes only to help illustrate the potential value of your MNA
        pension. <br />
        The calculations assume you continue working in the MNA contract to the
        age <br />
        of collecting benefits and earn credits roughly equal to your most
        recent <br />
        benefit amount for that entire time. Your actual benefit amount will be{" "}
        <br />
        determined by your work history and will be provided to you by Wilson
        McShane. <br />
      </ReactTooltip>
    </div>
  );
}
